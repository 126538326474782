<template>
	<div class="home">

		<div>
			<div class="home-top">
				<div class="home-address" @click="goAddress">
					<van-icon name="location-o" /><span>{{city}}</span>
				</div>
				<van-search v-model="value" placeholder="请输入搜索关键词" @search="onSearch(value)"
					@focus="focusPrice($event)">
					<!-- <template #action>
						<div @click="onSearch(value)">搜索</div>
					</template> -->
				</van-search>
			</div>

			<van-swipe :autoplay="3000">
				<van-swipe-item v-for="(item, index) in images" :key="index">
					<img class="home-image" v-lazy="imageUrl + item.image" />
				</van-swipe-item>
			</van-swipe>
			<div class="options-area">
				<div v-for="(v,i) in iconList" class="options">
					<img :src="imageUrl + v.image" alt="" class="options-icon">
					<span class="options-name">{{v.name}}</span>
				</div>
			</div>
			<!-- <van-tabs v-model="active" sticky @click="toggle" color="#ffa000"> -->
				<!-- <van-tab :title="item.title" v-for="item in tabData"> -->
					<!-- <van-pull-refresh v-model="isDownLoading" @refresh="onDownRefresh"> -->
					<van-list v-model="isUpLoading" :finished="upFinished" :immediate-check="false" :offset="10"
						finished-text="我是有底线的" @load="onLoadList" style="padding:10px 15px 50px 15px;">

						<div v-for="item in goodsList" class="goods-item" @click="goGoodsDetail(item)">
							<div class="goods-image">
								<img :src="imageUrl+ item.image" alt="" >
							</div>
							<div class="goods-info">

								<span class="goods-name">{{item.name}}</span>
								<span class="shopName">{{item.shop_name}}</span>
								<span class="goods-price">￥{{item.price}}</span>
							</div>

						</div>
					</van-list>

					<!-- </van-pull-refresh> -->

				<!-- </van-tab> -->
			<!-- </van-tabs> -->
		</div>
	</div>
</template>
<script>
	import {
		getIconList,
		getGoodsList,
		getIndexImage,
		getIndexCity,
		getSearch
	} from '@/api/index'
	import {
		toUrl
	} from '@/utils/tools'
	import {
		Dialog,
		Toast
	} from 'vant';
	export default {

		data() {
			return {
				value: '',
				iconList: '',
				imageUrl: this.url.imageUrl,
				active: 0,
				images: '',
				tabData: [{
						title: '团购',
						type: 1
					},
					{
						title: '外卖',
						type: 2
					},
					// {
					// 	title: '预约',
					// 	type: 3
					// }
				],
				nullTip: '空空如也~',
				limit: 10, // 每页条数
				page: 1, // 页码
				type: 1,
				goodsList: [], // 请求数据
				isDownLoading: false, // 下拉刷新
				isUpLoading: false, // 上拉加载
				upFinished: false, // 上拉加载完毕
				offset: 100, // 滚动条与底部距离小于 offset 时触发load事件
				city: '',
				locationCity: '',
				lng: '',
				lat: '',
				code: '',
				listAll: ''
			}
		},
		mounted() {
			// console.log(this.$store.state)
			var that = this
			getIconList().then((res) => {
				// console.log(res)
				that.iconList = res.data
			})
			if (!that.$store.state.app.city) {
				Dialog.confirm({
						message: '请选择地址',
					})
					.then(() => {
						// on confirm
						this.$router.push({
							path: '/address'
						})
					})
					.catch(() => {
						// on cancel
					});
			}

			if (that.$store.state.app.city) {
				this.locationCity = that.$store.state.app.city
				console.log(this.locationCity)
				that.city = this.locationCity[0].name
				that.lng = this.locationCity[0].lng
				that.lat = this.locationCity[0].lat
				that.code = this.locationCity[0].code
			} else {
				upsdk.pluginReady(function() {
					//经纬度
					upsdk.getLocationGps({
						success: function(res) {
							console.log(res)
						},
						fail: function() {}
					})
					//首页城市
					upsdk.getLocationCity({
						success: function(cityCd) {
							getIndexCity({
								code: cityCd
							}).then((res) => {
								that.city = res.data.name
								that.$store.commit('LOCATION_CITY', res.data)

							})
						}
					})

				});
			}

			this.getGoodsList()
			this.getIndexImage()
		},
		methods: {
			focusPrice() {
				// console.log(12)
				this.$router.push({
					path: '/searchPage',
					query: {
						code: this.code,
						lng: this.lng,
						lat: this.lat
					}
				})
			},
			// 搜索
			// onSearch(val) {
			// 	console.log(val)
			// 	this.$router.push({
			// 		path: '/searchPage',
			// 		query: {
			// 			name: val,
			// 			code:this.code,
			// 			lng:this.lng,
			// 			lat:this.lat
			// 		}
			// 	})

			// },
			//获取轮播图
			getIndexImage() {
				getIndexImage({
					position: 'home'
				}).then((res) => {
					console.log(res)
					this.images = res.data
				})
			},
			goAddress() {
				this.$router.push({
					path: '/address'
				})
			},
			goGoodsDetail(item) {
				this.$router.push({
					path: '/goodsDetail',
					query: {
						id: item.id,
						type: item.type
					}
				})
			},
			toggle(name) {
				this.type = name + 1
				this.page = 1
				this.limit = 10
				this.upFinished = false
				this.goodsList = []
				this.getGoodsList()
			},
			getGoodsList() {
				this.$toast.loading({
					message: '加载中...',
					forbidClick: true,
				});
				let params = {}
				// params.type = this.type
				params.page = this.page
				params.limit = this.limit
				getGoodsList(params).then((res) => {
					console.log(res)
					const rows = res.data.data
					// console.log(rows.length)
					if (rows.length === 0) {
						// 加载结束
						this.upFinished = true
						return
					}
					if (rows.length < this.limit) {
						// 最后一页不足10条的情况
						this.upFinished = true
					}
					// 处理数据
					if (this.page === 1) {
						this.goodsList = rows
					} else {
						this.goodsList = this.goodsList.concat(rows)
					}
					// console.log(this.goodsList)
					this.$toast.clear()
				})
			},
			onDownRefresh() {

			},
			// 上拉加载请求方法
			onLoadList() {
				this.page++
				this.getGoodsList()

			},
		}
	}
</script>
<style lang="less" scoped>
	@import "~@/assets/style/preset.less";
	

	.home {
		background-color: #f6f6f6;
		// .van-swipe {
		// 	width: 100%;
		// 	height: 160px;
		// }
		.van-tabs {
			background-color: #f6f6f6;
			margin-top: 10px;
			// .van-sticky{
			// 	.van-tabs__wrap{
			// 		.van-tabs__nav--card{
			// 			border: none !important;
			// 		}
			// 	}
			// }
		}


		.home-image {
			width: 100%;
			height: 160px;
		}

		.home-top {
			.home-address {
				display: flex;
				align-items: center;
				padding-left: 10px;
				width: 15%;

				span {
					font-size: 12px;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;
				}
			}

			.van-search {
				width: 85%;
			}

			display: flex;
			align-items: center;
			background-color: #FFFFFF;
			justify-content: space-between;
		}

		.options-area {
			display: flex;
			flex-wrap: wrap;
			background-color: #FFFFFF;
			padding: 10px 0;

			// border-bottom: solid 1px #333333;
			.options {
				display: flex;
				align-items: center;
				flex-direction: column;
				width: 20%;
				margin-bottom: 10px;

				.options-icon {
					width: 45px;
					height: 45px;
				}

				.options-name {
					margin-top: 5px;
					font-size: 12px;
					color: #666666;
				}
			}
		}

		.goods-item {
			display: flex;
			align-items: flex-start;
			background-color: #FFFFFF;
			border-radius: 5px;
			margin-bottom: 10px;
			padding: 5px 10px;
			height: 100px;
			box-sizing: border-box;
			justify-content: flex-start;

			.goods-image {
				width: 100px;
				height: 88px;
				overflow: hidden;
				// display: flex;
				// justify-content: center;
				img{
					width: 100%;
					  height: 100%;
					  // padding-top: 100%;
					  // overflow: hidden;
					  background-position: center center;
					  background-repeat: no-repeat;
					  background-size: cover;
					  -webkit-background-size: cover;
					  -moz-background-size: cover;
					border-radius: 10px;
				}
			}

			.goods-info {
				display: flex;
				flex-direction: column;
				height: 100%;
				padding-left: 15px;
				width: 60%;
				justify-content: space-between;

				.goods-price {
					font-size: 18px;
					font-weight: bold;
					color: red;
				}

				.goods-name {
					font-size: 14px;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
				}

				.shopName {
					font-size: 13px;
					color: #777;
				}
			}

		}
	}
</style>
